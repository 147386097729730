<template>
  <v-data-table
    v-bind="$attrs"
    :headers="headers"
    :items="items"
    mobile-breakpoint="0"
    hide-default-footer>
    <template v-slot:item.spend="{ item }">
      <span v-private>
        {{ CurrencyFormatLong(item.spend) }}
      </span>
    </template>
    <template v-slot:item.promo="{ item }">
      <span>
        Monthly Payment <br>
        <span v-private>{{ item.promo }}</span>
      </span>
    </template>
    <template v-slot:item.standard="{ item }">
      <span>
        Monthly Payment <br>
        <span v-private>{{ item.standard }}</span>
      </span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';

export default defineComponent({
  name: 'LoanCompareTable',

  props: {
    items: { type: Object, default: () => ({}) },
  },

  data() {
    const headers: Array<TableHeaderInterface> = [
      {
        title: 'If You Spend',
        align: 'start',
        sortable: false,
        value: 'spend',
        width: 100,
      },
      {
        title: 'During Promotion',
        align: 'start',
        sortable: false,
        value: 'promo',
        width: 100,
      },
      {
        title: 'After Promotion',
        align: 'start',
        sortable: false,
        value: 'standard',
        width: 100,
      },
    ];
    return {
      headers,
    };
  },

  methods: {
    CurrencyFormatLong,
  },
});
</script>

<style lang="scss" scoped>
:deep() {
  .v-table-header span,
  tbody span {
    color: var(--grayscale-color-1);
    font-size: 0.75rem;
  }
}
</style>
