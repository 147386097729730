<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    :min-width="minWidth"
    :max-width="maxWidth">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        class="filter-activator"
        :class="{ 'filter-activator--filtered': selected.length > 0 }">
        <span>{{ label }}</span>
        <v-icon>mdi-filter-variant</v-icon>
      </div>
    </template>

    <v-card class="bg-white multiselect-filter">
      <v-card-title class="filter-title">
        <strong>{{ title }}</strong>
        <v-btn
          icon="mdi-close"
          variant="plain"
          @click="menuOpen = false" />
      </v-card-title>

      <text-input
        v-if="searchable"
        v-model="search"
        clearable
        class="px-2"
        in-place
        hide-details
        append-icon="mdi-magnify" />

      <v-card-text>
        <v-list class="multiselect-filter__list">
          <v-list-item
            v-for="item in filteredItems"
            :key="item.value"
            @click="toggleSelected(item.value, $event)">
            <v-list-item-action start>
              <v-checkbox-btn
                :ripple="false"
                hide-details
                :model-value="isSelected(item.value)" />
            </v-list-item-action>

            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <custom-button
          full-width
          class="action-button"
          size="small"
          :disabled="!hasChanges"
          @click="apply">
          Apply
        </custom-button>

        <div class="filter-footer">
          <custom-button
            full-width
            variant="text"
            class="action-button"
            size="small"
            :disabled="!internalSelected.length"
            @click="clearSelection">
            Clear Selection
          </custom-button>
        </div>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import TextInput from '@/components/Inputs/Text.vue';

export interface Filter {
  label: string;
  value: string | number | boolean;
}

export default defineComponent({
  name: 'MultiselectFilter',

  components: { CustomButton, TextInput },

  props: {
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    items: { type: Array as PropType<Filter[]>, required: true },
    searchable: { type: Boolean, default: false },
    selected: { type: Array as PropType<string[]>, default: () => [] },
    minWidth: { type: String, default: '250px' },
    maxWidth: { type: String, default: '250px' },
  },

  data() {
    return {
      search: '',
      menuOpen: false,
      internalSelected: [] as string[],
    };
  },

  computed: {
    filteredItems() {
      if (!this.search) return this.items;

      const needle = this.search.toLowerCase();
      return this.items.filter(o => o.label.toLowerCase().includes(needle));
    },

    hasChanges() {
      return this.internalSelected.length !== this.selected.length
        || this.internalSelected.some(it => !this.selected.includes(it));
    },
  },

  watch: {
    selected: {
      immediate: true,
      handler(value) {
        this.internalSelected = [...value];
      },
    },
  },

  methods: {
    isSelected(item: string) {
      return this.internalSelected.indexOf(item) !== -1;
    },

    toggleSelected(item: string, value: boolean | null) {
      const selected = this.internalSelected;

      if (value && !selected.includes(item)) {
        selected.push(item);
      } else {
        selected.splice(selected.indexOf(item), 1);
      }

      this.internalSelected = selected;
    },

    clearSelection() {
      if (this.selected.length) {
        this.$emit('update:selected', []);
      } else {
        this.internalSelected = [];
      }
    },

    apply() {
      this.$emit('update:selected', this.internalSelected);
      this.menuOpen = false;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep() {
  .v-btn--icon.v-btn--size-small,
  .v-btn--icon.v-btn--size-small .v-icon {
    height: 1.25rem !important;
    width: 1.25rem !important;
    font-size: 1.5rem;
  }

  .v-selection-control__input:hover {
    --v-hover-opacity: 0;
  }
}

.filter-activator {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  // Negative margin to offset padding added
  // So it aligns with the other headers
  margin-left: -0.5rem;
  width: fit-content;
  padding: 0.25rem 0.5rem;

  &--filtered {
    background-color: var(--grayscale-color-4);
    border-radius: 0.25rem;
  }
}

.v-card-text {
  padding: 0;
}

.v-card-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
}

:deep() .v-list-item__content {
  display: flex;
  align-items: center;
}

.filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0rem 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--grayscale-color-5);
  color: var(--grayscale-color-1)
}

.v-list-item .v-input--checkbox {
  padding: 0 !important;
  margin: 0 !important;
}

.v-list-item-title,
.custom-button :deep(.v-btn__content) {
  font-size: 0.875rem;
}

.multiselect-filter__list {
  max-height: 16.25rem;
  overflow-y: auto;
}

.custom-button {
  margin: 0.5rem auto;
  width: 85% !important;
}

.filter-footer {
  width: 100%;
  text-align: center;
  background-color: var(--grayscale-color-5);

  .v-btn.custom-button.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):not(.button-link) {
    color: var(--grayscale-color-2) !important;
    background-color: transparent !important;
  }
}
</style>
