<template>
  <standard-dialog
    :dialog="show"
    :on-cancel="closeModal">
    <template #title>
      <h3 class="dialog-title mt-0" data-test="ownerInformationHeader">
        Owner Information
      </h3>
    </template>
    <template #body>
      <p data-test="asYouEnterInfoLabel">
        As you enter information on the owners of your company,
        you will indicate their percentage of ownership.
      </p>
      <div>
        <img
          data-test="ownershipPercentageImg"
          src="@/assets/images/ownership_percentage.gif"
          alt="Ownership Percentage"
          loading="eager"
          style="width: 100%;">
      </div>
      <p data-test="additionalOwnersLabel">
        Add additional owners as necessary until you’ve reached a minimum of 51% ownership.
        After that you’ll be able to proceed to the next step.
      </p>
    </template>
    <template #actions>
      <custom-button
        full-width
        size="small"
        @click="closeModal">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'CompleteStepInfo',

  components: {
    CustomButton,
    StandardDialog,
  },

  props: {
    show: { type: Boolean, default: false },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.dialog-title {
  font-size: 1.125rem;
  color: var(--grayscale-color-1);
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}
</style>
