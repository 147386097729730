<template>
  <standard-dialog
    width="420"
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :hide-header-close="true"
    :update-title-bcg-color="true">
    <template #title>
      <h5 class="dialog-title mb-0 mt-0">
        Important Changes to Same-As-Cash Loan Products
      </h5>
    </template>

    <template #body>
      <div class="dialog-paragraph mt-4">
        <p class="mb-0">
          Effective April 1, 2024, please note that we've made changes to our
          Same-As-Cash loan products.
          Now, the name “Same-As-Cash” has been changed to “Deferred Interest”
          to clarify how the loans operate.
        </p>
      </div>

      <div class="dialog-paragraph">
        <p class="mb-0">
          We've also adjusted the APRs to reflect market rates, and have increased the
          amortization length from 4 years to 7 years.
          No changes have been made to merchant fees on these loan products.
        </p>
      </div>

      <custom-button full-width @click="$emit('acknowledgeChanges')">
        I acknowledge these changes
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'ImportantChangesDialog',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    showDialog: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
  text-align: left;
  color: var(--grayscale-color-1);
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  background-color: var(--grayscale-color-5) !important;
}

.dialog-paragraph {
  margin-bottom: 2rem;

  p {
    text-align: left;
  }
}

:deep(.v-card-text) {
  padding: 0 1rem 1rem 1rem !important;
}
</style>
