<template>
  <standard-dialog
    width="600"
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :hide-header-close="true"
    card-class="change-default-bcg-color">
    <template #title>
      <h5 class="dialog-title mb-0 mt-0">
        Loan product Changes October 15th, 2023
      </h5>
    </template>

    <template #body>
      <div class="dialog-paragraph">
        <p>
          Our No-Interest If Paid In Full ({{ diProductName }}) products will now require
          a minimum monthly payment during the promotional period,
          whereas they previously did not require a minimum monthly
          payment during the promotional period.
        </p>

        <p class="mb-0">
          These Product ID changes  will be updated automatically in your Merchant Portal
          on the evening of October 15th, 2023, and will not impact merchant fees.
        </p>
      </div>

      <div class="info-table">
        <v-data-table
          :headers="headers"
          :items="items"
          :hide-default-footer="true"
          class="v-elevation-4" />
      </div>

      <custom-button full-width @click="$emit('acknowledgeChanges')">
        I Acknowledge These Changes
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'HealthCareDialogOctober',
  components: {
    StandardDialog,
    CustomButton,
  },
  mixins: [FeatureFlagsMixin],
  props: {
    showDialog: { type: Boolean, default: false },
  },
  data() {
    return {
      headers: [
        {
          text: 'Impacted Products',
          align: 'start',
          sortable: false,
          value: 'products',
        },
        {
          text: 'Old Product ID',
          align: 'start',
          sortable: false,
          value: 'oldproduct',
        },
        {
          text: 'New Product ID',
          align: 'start',
          sortable: false,
          value: 'newproduct',
        },
      ],
    };
  },
  computed: {
    diProductName() {
      return this.isFeatureEnabled(FeatureFlagsConstants.MERCHANT_DEFERRED_INTEREST)
        ? 'Deferred Interest'
        : 'Same-As-Cash';
    },
    items() {
      return [
        {
          products: `${this.diProductName} (6 Months)`,
          oldproduct: 'PD-NN-06-04',
          newproduct: 'PD-NY-06-04',
        },
        {
          products: `${this.diProductName} (6 Months)`,
          oldproduct: 'PD-NN-12-04',
          newproduct: 'PD-NY-12-04',
        },
        {
          products: `${this.diProductName} (6 Months)`,
          oldproduct: 'PD-NN-24-04',
          newproduct: 'PD-NY-24-04',
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.dialog-paragraph {
  background-color: #fff;
  border-radius: 18px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.info-table {
  margin-bottom: 2rem;
}

:deep(.v-table) {
  .v-table-header tr th span {
    color: var(--grayscale-color-1);
  }

  tbody tr td {
    color: var(--grayscale-color-1);
  }
}
</style>
