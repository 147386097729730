<template>
  <div :class="classList">
    <custom-alert
      v-if="moovPending"
      class="activity-center-alert"
      type="error">
      You will not be able to transact until an account admin verifies micro-deposits for your
      bank account in <span class="font-weight-bold">Admin > Payment Settings.</span>
    </custom-alert>

    <v-container class="new-loan-application-mobile pa-0">
      <new-loan-application v-if="!hideNewConsumerInvitation" />
    </v-container>

    <v-row class="row-wrapper">
      <v-col class="d-flex summary-card-wrap" sm="4">
        <customer-summary-card class="merchant__activity-center__customer-summary-card" />
      </v-col>

      <v-col sm="8" class="new-loan-application py-0">
        <new-loan-application v-if="!hideNewConsumerInvitation" class="new-loan-application__button" />

        <consumer-activity v-if="humanFriendlyAppStatusIsEnabled" />

        <recent-loan-applications v-else class="merchant__activity-center__recent-loan-applications" />
      </v-col>
    </v-row>

    <recent-activity class="merchant__activity-center__recent-activity" />

    <products-and-pricing-dialog
      :show-dialog="showProductAndPricingDialogJuly"
      date="July 31st, 2023"
      @acknowledgeChanges="changesConfirmedJuly" />

    <products-and-pricing-dialog
      :show-dialog="showProductAndPricingDialogSeptember"
      date="September 10th, 2023"
      @acknowledgeChanges="changesConfirmedSeptember" />

    <health-care-dialog-october :show-dialog="showHealthCareDialogOctober" @acknowledgeChanges="changesConfirmedOctober" />

    <home-improvement-dialog :show-dialog="showHomeImprovementDialog" @ratesheetPage="goToRateSheetPage" />

    <important-changes-dialog
      :show-dialog="showImportantChangesDialog"
      @acknowledgeChanges="submitAcknowledgeImportantChanges" />
  </div>
</template>

<script lang="ts">
import CustomerSummaryCard from '@/components/CustomerSummaryCard/index.vue';
import NewLoanApplication from '@/components/CustomerSummaryCard/Modals/NewLoanApplication.vue';
import RecentActivity from '@/components/Merchant/Portal/RecentActivity.vue';
import RecentLoanApplications from '@/components/Merchant/Portal/RecentLoanApplications.vue';
import SelectLoanApplicationMixin from '@/mixins/Merchant/SelectLoanApplicationMixin';
import ProductsAndPricingDialog from '@/components/Merchant/Portal/ProductsAndPricingDialog.vue';
import HealthCareDialogOctober from '@/components/Merchant/Portal/HealthCareDialogOctober.vue';
import HomeImprovementDialog from '@/components/Merchant/Portal/HomeImprovementDialog.vue';
import { postConsentTypes } from '@/utils/Consents';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import BANK_ACCOUNT_STATUSES from '@/constants/PaymentConstants';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import { defineComponent } from 'vue';
import ImportantChangesDialog from '@/components/Merchant/Portal/ImportantChangesDialog.vue';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';
import ConsumerActivity from '@/components/ConsumerActivity/index.vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import useMPAccess from '@/components/Merchant/composables/useMPAccess';

export default defineComponent({
  name: 'ActivityCenter',
  components: {
    NewLoanApplication,
    RecentActivity,
    RecentLoanApplications,
    CustomerSummaryCard,
    ProductsAndPricingDialog,
    CustomAlert,
    HealthCareDialogOctober,
    HomeImprovementDialog,
    ImportantChangesDialog,
    ConsumerActivity,
  },
  mixins: [
    MerchantPermissionsMixin,
    SelectLoanApplicationMixin,
    FeatureFlagsMixin,
  ],
  setup() {
    const { hideNewConsumerInvitation } = useMPAccess();

    return {
      hideNewConsumerInvitation,
    };
  },
  data() {
    return {
      baseClass: 'merchant-portal-page',
    };
  },
  computed: {
    classList() {
      const classList: Array<string> = [
        this.baseClass,
        `${this.baseClass}--activity-center`,
      ];

      return classList.join(' ');
    },
    recentlyApprovedConsumerAppId() :string {
      return this.$store.getters['MerchantPortal/getRecentlyApprovedConsumerAppId'];
    },
    showProductAndPricingDialogJuly(): boolean {
      return this.$store.getters['MerchantPortal/getShowProductAndPricingDialogJuly'];
    },
    showProductAndPricingDialogSeptember(): boolean {
      return this.$store.getters['MerchantPortal/getShowProductAndPricingDialogSeptember'];
    },
    showHealthCareDialogOctober(): boolean {
      return this.$store.getters['MerchantPortal/getShowHealthCareDialogOctober'];
    },
    showHomeImprovementDialog(): boolean {
      return this.$store.getters['MerchantPortal/getShowHomeImprovementDialog']
        && this.isAdminOrAbove;
    },
    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
    moovPending(): boolean {
      return this.paymentProviders?.moov?.status === BANK_ACCOUNT_STATUSES.PENDING;
    },
    showImportantChangesDialog(): boolean {
      return this.$store.getters['MerchantPortal/getShowImportantChangesDialog'];
    },
    humanFriendlyAppStatusIsEnabled() {
      return this.isFeatureEnabled(FeatureFlagsConstants.HUMAN_FRIENDLY_APP_STATUS);
    },
    merchantAppUuid() {
      return this.$store.getters['MerchantPortal/getMerchantApplicationUuid'];
    },
  },
  watch: {
    recentlyApprovedConsumerAppId: {
      handler(application: string) {
        if (application) {
          this.$store.dispatch('MerchantPortal/selectLoanApplication', application);
        } else {
          this.$store.dispatch('MerchantPortal/deSelectLoanApplication');
        }
      },
      immediate: true,
    },
  },

  methods: {
    async changesConfirmedJuly() {
      const status = await postConsentTypes({
        consentTypes: [ConsentTypesEnum.PRODUCT_AND_PRICING_JULY],
        merchant_application_uuid: this.merchantAppUuid,
        entity: ConsentEntityTypes.MERCHANT,
        consentedOn: true,
      });

      if (status === 201) {
        this.$store.dispatch('MerchantPortal/setShowOfProductAndPricingDialogJuly', false);
      }
    },
    async changesConfirmedSeptember() {
      const status = await postConsentTypes({
        consentTypes: [ConsentTypesEnum.PRODUCT_AND_PRICING_SEPTEMBER],
        merchant_application_uuid: this.merchantAppUuid,
        entity: ConsentEntityTypes.MERCHANT,
        consentedOn: true,
      });

      if (status === 201) {
        this.$store.dispatch('MerchantPortal/setShowOfProductAndPricingDialogSeptember', false);
      }
    },
    async changesConfirmedOctober() {
      const status = await postConsentTypes({
        consentTypes: [ConsentTypesEnum.HEALTH_CARE_OCTOBER],
        merchant_application_uuid: this.merchantAppUuid,
        entity: ConsentEntityTypes.MERCHANT,
        consentedOn: true,
      });

      if (status === 201) {
        this.$store.dispatch('MerchantPortal/setShowOfHealthCareDialogOctober', false);
      }
    },
    async goToRateSheetPage() {
      const status = await postConsentTypes({
        consentTypes: [ConsentTypesEnum.HOME_IMPROVEMENT],
        merchant_application_uuid: this.merchantAppUuid,
        entity: ConsentEntityTypes.MERCHANT,
        consentedOn: true,
      });

      if (status === 201) {
        this.$store.dispatch('MerchantPortal/setShowOfHomeImprovementDialog', false);
        this.$router.push({ name: 'merchant-rate-sheets' });
      }
    },
    async submitAcknowledgeImportantChanges() {
      const status = await postConsentTypes({
        consentTypes: [ConsentTypesEnum.IMPORTANT_CHANGES],
        merchant_application_uuid: this.merchantAppUuid,
        entity: ConsentEntityTypes.MERCHANT,
        consentedOn: true,
      });

      if (status === 201) {
        this.$store.dispatch('MerchantPortal/setShowOfImportantChangesDialog', false);
      } else if (status > 299) {
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: 'Something went wrong',
          type: GlobalAlertTypes.ERROR,
          timed: true,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/merchant-portal-page";

@media only screen and (min-width: 575px) {
  .v-row {
    margin: 0 -0.75rem !important;
  }
}

.merchant-portal-page {
  &--activity-center {
    border: none;

    .new-loan-application-mobile {
      display: none;
    }

    .summary-card-wrap {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    .merchant {
      &__activity-center {
        &__new-loan-application,
        &__customer-summary-card,
        &__recent-loan-application,
        &__recent-activity {
          width: 100%;
          margin-bottom: 2rem;
          margin-top: 1rem;

          @include mobile {
            margin-bottom: 1.5rem;
          }
        }
        &__customer-summary-card {
          margin-top: 0;
          margin-bottom: 0;
          z-index: 5;
        }
        &__recent-loan-applications {
          margin: 1.25rem 0 2rem;

          @include mobile {
            margin: 0 0 1.5rem;
          }
        }
        &__recent-activity {
          border: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .new-loan-application-mobile {
    display: block !important;
  }

  .v-row {
    margin: 0;
  }

  .row-wrapper {
    flex-direction: column;
  }

  .full-width {
    width: 100%;
  }

  .summary-card-wrap {
    padding: 0;

    .simple-card {
      margin-bottom: 0;
      border: none;
    }
  }

  :deep() {
    .merchant{
      &__activity-center {
        &__new-loan-application {
          margin: 1rem 0;
        }

        &__customer-summary-card {
          margin-bottom: 1rem !important;

          @include mobile {
            margin-bottom: 1.5rem !important;
          }
        }

        &__recent-loan-applications {
          border: none;
        }
      }
    }
  }

  .new-loan-application {
    padding: 0;

    &__button {
      display: none;
    }

    .simple-card {
      margin-top: 0;
    }
  }
}

.activity-center-alert {
  margin-bottom: 2rem;
}
</style>
