import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bbcdae2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rate-sheets-options" }
const _hoisted_2 = {
  key: 0,
  class: "rate-sheet-simple-header"
}
const _hoisted_3 = { class: "v-data-table__td" }
const _hoisted_4 = { class: "v-data-table__td-title" }
const _hoisted_5 = { class: "v-data-table__td-value" }
const _hoisted_6 = { class: "v-data-table__td" }
const _hoisted_7 = { class: "d-flex space-between v-data-table__td-title" }
const _hoisted_8 = { class: "v-data-table__td" }
const _hoisted_9 = { class: "d-flex space-between v-data-table__td-title" }
const _hoisted_10 = { class: "v-data-table__td-value" }
const _hoisted_11 = { class: "v-data-table__td" }
const _hoisted_12 = { class: "d-flex space-between v-data-table__td-title" }
const _hoisted_13 = { class: "v-data-table__td-value" }
const _hoisted_14 = { class: "v-data-table__td" }
const _hoisted_15 = { class: "d-flex space-between v-data-table__td-title" }
const _hoisted_16 = { class: "v-data-table__td-value" }
const _hoisted_17 = {
  class: "v-data-table__td",
  style: {"width":"100%"}
}
const _hoisted_18 = { class: "v-data-table__td-title" }
const _hoisted_19 = { class: "v-data-table__td-value" }
const _hoisted_20 = {
  key: 0,
  class: "d-flex justify-end v-data-table__td",
  style: {"width":"100%"}
}
const _hoisted_21 = { class: "v-data-table__td-value" }
const _hoisted_22 = {
  key: 1,
  class: "d-flex justify-end v-data-table__td",
  style: {"width":"100%"}
}
const _hoisted_23 = { class: "v-data-table__td-value" }
const _hoisted_24 = {
  key: 2,
  class: "d-flex justify-end v-data-table__td",
  style: {"width":"100%"}
}
const _hoisted_25 = { class: "v-data-table__td__cell" }
const _hoisted_26 = { class: "text-left" }
const _hoisted_27 = { class: "text-right" }
const _hoisted_28 = { class: "text-right" }
const _hoisted_29 = { class: "text-right" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 1,
  class: "more-data-action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rate_sheet_options_data_table_header = _resolveComponent("rate-sheet-options-data-table-header")!
  const _component_rate_sheet_options_data_table_legend = _resolveComponent("rate-sheet-options-data-table-legend")!
  const _component_header_with_tooltip = _resolveComponent("header-with-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_preferences = _resolveComponent("preferences")!
  const _component_rate_sheet_options_more_data = _resolveComponent("rate-sheet-options-more-data")!
  const _component_selector = _resolveComponent("selector")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_simple_card = _resolveComponent("simple-card")!
  const _component_custom_dialog = _resolveComponent("custom-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_simple_card, { class: "rate-sheets-options__data-table" }, _createSlots({
      body: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["simple-card__body-text", _ctx.rateSheetTable ? 'ratesheet-table' : ''])
        }, [
          (_ctx.showLegend)
            ? (_openBlock(), _createBlock(_component_rate_sheet_options_data_table_legend, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_data_table, {
            headers: _ctx.headers,
            items: _ctx.rateSheetOptions,
            loading: _ctx.processing,
            mobile: _ctx.isMobile,
            "data-test": _ctx.customDataTest,
            "items-per-page": 10,
            class: "minimal-pagination",
            "item-key": "rate_sheet_merchant_product_price_id",
            "row-props": _ctx.toggleRowCustomClass,
            "items-per-page-options": [10]
          }, {
            "header.product_name": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "header.full_term": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "header.apr": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "header.merchant_fee": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            item: _withCtx(({ item }) => [
              (_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("tr", {
                    key: 0,
                    class: _normalizeClass(["v-data-table__tr--mobile", item.has_ripple && !_ctx.isFromOfferCodes ? 'ripple-animation' : ''])
                  }, [
                    _createElementVNode("td", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.headers[0].title), 1),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(item.short_description), 1)
                    ]),
                    _createElementVNode("td", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_header_with_tooltip, {
                          title: _ctx.headers[1].title,
                          tooltip: _ctx.headers[1].tooltip,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTooltip = _ctx.headers[1]))
                        }, null, 8, ["title", "tooltip"])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["v-data-table__td-value", { 'has-fallback': item.has_fallback }])
                      }, [
                        _createTextVNode(_toDisplayString(item.product_name) + " ", 1),
                        (item.has_fallback)
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 0,
                              size: "1.5rem",
                              color: "var(--grayscale-color-1)",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show-fallback-modal')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" mdi-information-outline ")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _createElementVNode("td", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_header_with_tooltip, {
                          title: _ctx.headers[2].title,
                          tooltip: _ctx.headers[2].tooltip,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeTooltip = _ctx.headers[2]))
                        }, null, 8, ["title", "tooltip"])
                      ]),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(item.full_term), 1)
                    ]),
                    _createElementVNode("td", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_header_with_tooltip, {
                          title: _ctx.headers[3].title,
                          tooltip: _ctx.headers[3].tooltip,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeTooltip = _ctx.headers[3]))
                        }, null, 8, ["title", "tooltip"])
                      ]),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(item.apr), 1)
                    ]),
                    _createElementVNode("td", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_header_with_tooltip, {
                          title: _ctx.headers[4].title,
                          tooltip: _ctx.headers[4].tooltip,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.activeTooltip = _ctx.headers[4]))
                        }, null, 8, ["title", "tooltip"])
                      ]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.merchantFeeLabel(item.merchant_fee)), 1)
                    ]),
                    _createElementVNode("td", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.headers[5].title), 1),
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_preferences, {
                          "customer-preferences": item.customer_preferences
                        }, null, 8, ["customer-preferences"])
                      ])
                    ]),
                    (!_ctx.additionalProductOfferings && !_ctx.switchToggle)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_rate_sheet_options_more_data, {
                              "merchant-onboarding": _ctx.merchantOnboarding,
                              "rate-sheet-option": item,
                              "is-mobile": "",
                              onToggle: _ctx.removeRipple
                            }, null, 8, ["merchant-onboarding", "rate-sheet-option", "onToggle"])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.additionalProductOfferings || _ctx.switchToggle)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_rate_sheet_options_more_data, {
                              "merchant-onboarding": _ctx.merchantOnboarding,
                              "rate-sheet-option": item,
                              "rate-sheet-action": "Add To Rate Sheet",
                              onToggle: ($event: any) => (_ctx.selectRateSheetOption(item))
                            }, null, 8, ["merchant-onboarding", "rate-sheet-option", "onToggle"])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.additionalProductOfferings || _ctx.switchToggle)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_selector, {
                              class: "button-full-width",
                              "button-toggle": _ctx.buttonToggle,
                              "switch-toggle": _ctx.switchToggle,
                              "disabled-add": _ctx.disableAddBtns,
                              selected: item.is_selected,
                              "additional-product-offerings": _ctx.additionalProductOfferings,
                              onToggle: ($event: any) => (_ctx.selectRateSheetOption(item))
                            }, null, 8, ["button-toggle", "switch-toggle", "disabled-add", "selected", "additional-product-offerings", "onToggle"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 2))
                : (_openBlock(), _createElementBlock("tr", {
                    key: 1,
                    class: _normalizeClass(item.has_ripple && !_ctx.isFromOfferCodes ? 'ripple-animation' : '')
                  }, [
                    _createElementVNode("td", _hoisted_26, _toDisplayString(item.short_description), 1),
                    _createElementVNode("td", {
                      class: _normalizeClass(["text-left", { 'has-fallback': item.has_fallback }])
                    }, [
                      _createTextVNode(_toDisplayString(item.product_name) + " ", 1),
                      (item.has_fallback)
                        ? (_openBlock(), _createBlock(_component_v_icon, {
                            key: 0,
                            size: "1.5rem",
                            color: "var(--grayscale-color-1)",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('show-fallback-modal')))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" mdi-information-outline ")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("td", _hoisted_27, _toDisplayString(item.full_term), 1),
                    _createElementVNode("td", _hoisted_28, _toDisplayString(item.apr), 1),
                    _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.merchantFeeLabel(item.merchant_fee)), 1),
                    _createElementVNode("td", null, [
                      _createVNode(_component_preferences, {
                        "customer-preferences": item.customer_preferences
                      }, null, 8, ["customer-preferences"])
                    ]),
                    (!_ctx.additionalProductOfferings && !_ctx.switchToggle)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_30, [
                          _createVNode(_component_rate_sheet_options_more_data, {
                            "merchant-onboarding": _ctx.merchantOnboarding,
                            "rate-sheet-option": item,
                            onToggle: _ctx.removeRipple
                          }, null, 8, ["merchant-onboarding", "rate-sheet-option", "onToggle"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.additionalProductOfferings || _ctx.switchToggle)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_31, [
                          _createVNode(_component_rate_sheet_options_more_data, {
                            "merchant-onboarding": _ctx.merchantOnboarding,
                            "rate-sheet-option": item,
                            "is-additional": true,
                            "rate-sheet-action": "Add To Rate Sheet",
                            onToggle: ($event: any) => (_ctx.selectRateSheetOption(item))
                          }, null, 8, ["merchant-onboarding", "rate-sheet-option", "onToggle"]),
                          _createVNode(_component_selector, {
                            class: "button-full-width",
                            "button-toggle": _ctx.buttonToggle,
                            "switch-toggle": _ctx.switchToggle,
                            "disabled-add": _ctx.disableAddBtns,
                            selected: item.is_selected,
                            "additional-product-offerings": _ctx.additionalProductOfferings,
                            "from-rate-sheet": !!_ctx.isFromOfferCodes,
                            "product-name": item.product_name,
                            "data-test": "addToRateSheet",
                            onToggle: ($event: any) => (_ctx.selectRateSheetOption(item))
                          }, null, 8, ["button-toggle", "switch-toggle", "disabled-add", "selected", "additional-product-offerings", "from-rate-sheet", "product-name", "onToggle"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 2))
            ]),
            _: 1
          }, 8, ["headers", "items", "loading", "mobile", "data-test", "row-props"])
        ], 2)
      ]),
      _: 2
    }, [
      (_ctx.showHeader)
        ? {
            name: "header",
            fn: _withCtx(() => [
              (_ctx.simpleHeader)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.headerTitle), 1)
                  ]))
                : (_openBlock(), _createBlock(_component_rate_sheet_options_data_table_header, {
                    key: 1,
                    ref: "header",
                    "header-title": _ctx.headerTitle
                  }, null, 8, ["header-title"]))
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    (_ctx.activeTooltip)
      ? (_openBlock(), _createBlock(_component_custom_dialog, {
          key: 0,
          dialog: "",
          "show-cancel-cta": false,
          title: _ctx.activeTooltip.title,
          "button-label": "OK",
          "button-size": "small",
          width: "420",
          "on-cta": _ctx.closeTooltip,
          onCancel: _ctx.closeTooltip
        }, {
          body: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.activeTooltip.tooltip), 1)
          ]),
          _: 1
        }, 8, ["title", "on-cta", "onCancel"]))
      : _createCommentVNode("", true)
  ]))
}