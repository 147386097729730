import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abfd3816"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-elevation-1 rate-sheet-options-expandable-categories__card__body__cards__card" }
const _hoisted_2 = { class: "rate-sheet-options-expandable-categories__card__body__cards__card__header" }
const _hoisted_3 = { class: "rate-sheet-options-expandable-categories__card__body__cards__card__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.card.header), 1),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.card.value), 1)
    ])), [
      [_directive_private]
    ])
  ]))
}