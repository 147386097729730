import UsersRolesEnum from '@/enums/UsersRolesEnum';
import { NewMerchantUserDataInterface } from '@/interfaces/merchantPortal/NewMerchantUserDataInterface';
import { TransactionsDescriptionsInterface } from '@/interfaces/merchantPortal/TransactionsDescriptionsInterface';
import { LoanInvitation } from '@/interfaces/merchantPortal/LoanInvitation';
import { TransactionsInterface } from '@/interfaces/merchantPortal/TransactionsInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { MerchantPortalStateInterface } from '@/interfaces/states/MerchantPortalStateInterface';
import { MerchantUserInterface } from '@/interfaces/states/MerchantUserInterface';
import { MerchantInfoInterface, TransactionDetailsInterface } from '@/interfaces/statements/StatementDetailsInterface';
import { StatementSummaryInterface } from '@/interfaces/statements/StatementSummaryInterface';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): MerchantPortalStateInterface => ({
  menuItems: [
    {
      name: 'Activity Center',
      to: 'merchant-activity-center',
    },
    {
      name: 'All Transactions',
      to: 'merchant-all-transactions',
    },
    {
      name: 'Reports',
      // to: 'merchant-reports',
    },
    {
      name: 'Rate Sheet',
      to: 'merchant-rate-sheets',
      access: UsersRolesEnum.ADMIN,
    },
    {
      name: 'Statements',
      to: 'merchant-statements',
      access: UsersRolesEnum.ADMIN,
    },
    {
      name: 'Admin',
      to: 'merchant-admin',
      access: UsersRolesEnum.ADMIN,
    },
  ],
  recentTransactions: [] as Array<TransactionsInterface>,
  recentTransactionsCount: 0,
  paymentRequests: [],
  paymentRequestsCount: 0,
  paymentsRequests: [],
  newLoanApplications: [] as Array<any>,
  newLoanApplicationsCount: 0,
  newLoanInvitations: [] as Array<LoanInvitation>,
  newLoanInvitationsCount: 0,
  availableTransactions: [] as Array<TransactionsInterface>,
  availableTransactionsCount: 0,
  selectedLoanApplication: {},
  user: {},
  location: null,
  primaryLocation: null,
  locations: [],
  merchant: {},
  rateSheets: [] as Array<RateSheetData>,
  transactionsDescriptions: [] as Array<TransactionsDescriptionsInterface>,
  merchantUsers: [] as Array<MerchantUserInterface>,
  newMerchantUserData: {} as NewMerchantUserDataInterface,
  searchedItems: [],
  searchInput: '',
  statementDetails: {
    merchant_info: {} as MerchantInfoInterface,
    success_statement_details: [] as Array<TransactionDetailsInterface>,
    fail_statement_details: [] as Array<TransactionDetailsInterface>,
    statement_summary: {} as StatementSummaryInterface,
  },
  statements: [] as Array<StatementSummaryInterface>,
  merchantData: null,
  refundableTransactions: [],
  rateSheetExpandableCategory: 0,
  showResetPasswordMsg: false,
  merchantApplicationUuid: '',
  recentlyApprovedConsumerAppId: '',
  showProductAndPricingDialogJuly: false,
  showProductAndPricingDialogSeptember: false,
  showHealthCareDialogOctober: false,
  showAprCapComplianceDialog: false,
  showHomeImprovementDialog: false,
  showImportantChangesDialog: false,
  paymentProviders: {} as PaymentProvidersInterface,
  bankName: '',
  rateSheetSave: false,
  alertData: {},
  merchantPortalAccess: null,
  isIC1Sponsored: null,
});

export default {
  namespaced: true,
  state: initialState() as MerchantPortalStateInterface,
  getters,
  mutations: {
    ...mutations,
    resetModuleState(state: MerchantPortalStateInterface) {
      ResetModuleState(state, initialState());
    },
  },
  actions,
};
